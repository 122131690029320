// 拟任命人员
import { tongke } from '@/axios'
class Appointment {
  /**
   * @functionName import
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size  //年报
   * @Description   // 拟任命人员列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/quxian/zb/nrmry', { params })
  }
  /**
   * @functionName randomStatus
   * @param { Number }  params.cxly 抽选来源
   * @param { Number }  params.zbjh 增补计划
   * @Description 
   * @return { Object }
   */
  // 候选信息
  randomStatus(params) {
    return tongke.get('/quxian/zb/sjcxnrmry/info', { params })
  }
  /**
   * @functionName
   * @param { Object }
   * @param { Object } params.ncxrs  拟抽选人数
   * @param { Number }  params.cxly 抽选来源
   * @param { Number }  params.zbjh 增补计划
   * @Description 随机抽选(开始)
   * @return { Object }
   */
  start(params) {
    return tongke.get('/quxian/zb/sjcxnrmry/start', { params })
  }
  // 抽选进度
  progress() {
    return tongke.get('/quxian/zb/sjcxnrmry/progress')
  }
  // 抽选结果
  stop() {
    return tongke.get('/quxian/zb/sjcxnrmry/finish')
  }
  /**
   * @functionName publicView
   * @param { Object } params.ok  1-通过|0-不通过
   * @param { Object } params.sfzhm 身份证号码，批量通过传数组，不支持批量不通过
   * @param { Object } params.gsbtgyy  如果不通过，请填写不通过原因
   * @Description 拟任公示
   * @return { Object }
   */
  publicView(params) {
    return tongke.post('/quxian/nrgs', params)
  }
}
export default new Appointment()
